import { Box, Table, TableBody, TableContainer, Theme, Typography, useTheme } from "@mui/material"
import { useLanguage } from "../../../../state/language";
import portfolio from "../../data/portfolio";
import PortfolioHeader from "./PortfolioHeader";
import PortfolioRow from "./PortfolioRow";
import EmploymentRow from "./EmploymentRow";

interface PortfolioProps {};

const makeStyles = (theme: Theme) => {
    return {
        box: {
            mb: 5,
            alignContent: "center"
        },
        table: {
            maxHeight: {},
            margin: "auto",
            maxWidth: "1080px",
            overflow: "scroll",
            paddingHorizontal: 2,
            paddingVertical: 0
        },
        sticky: {
            position: "sticky",
            left: -1,
            zIndex: 2000,
            backgroundColor: theme.palette.primary.light
        },
        title: {
            marginTop: 8,
            fontSize: 32,
            marginBottom: 2
        },
        check: {
            maxWidth: "20px", padding: 1
        },

    }
}

export default function Portfolio(props: PortfolioProps) {
    const theme = useTheme();
    const styles = makeStyles(theme);
    const language = useLanguage();


    const workCategories = Object.keys(language.about.portfolio.categories).filter((v: string) => {
        return !["permanentRole", "interimManagement", "benelux", "europe", "global", "belgium", "luxemburg"].includes(v)
    });

    return <Box sx={styles.box}>
        <Typography sx={styles.title} px={{xs: 2, md: 8, lg: 48}}>{language.about.portfolio.title}</Typography>
    
        <TableContainer sx={styles.table} >
            <Table stickyHeader>
                <PortfolioHeader 
                    checkStyle={styles.check}
                    stickyStyle={styles.sticky} 
                    workCategories={workCategories}
                />
                <TableBody sx={{overflow: "scroll"}}>
                    <EmploymentRow 
                        name={language.about.portfolio.categories.permanentRole}
                        span={workCategories.length + 2}
                    />
                    {
                        portfolio.filter((value) => value.categories.includes("permanentRole")).map((value) => <PortfolioRow 
                            name={value.name} 
                            categories={value.categories} 
                            workCategories={workCategories}
                            stickyStyle={styles.sticky}
                            checkStyle={styles.check}
                        />)
                    }
                    <EmploymentRow 
                        name={language.about.portfolio.categories.interimManagement}
                        span={workCategories.length + 2}
                    />
{
                        portfolio.filter((value) => value.categories.includes("interimManagement")).map((value) => <PortfolioRow 
                            name={value.name} 
                            categories={value.categories} 
                            workCategories={workCategories}
                            stickyStyle={styles.sticky}
                            checkStyle={styles.check}
                        />)
                    }

                </TableBody>
            </Table>
        </TableContainer>
    </Box>
};