import { Box, TableCell, TableRow, Theme, Tooltip, useTheme } from "@mui/material"
import Language from "../../../language/Language";
import { useLanguage } from "../../../../state/language";
import { CheckCircle, Public } from "@mui/icons-material";

interface PortfolioRowProps {
    name: string
    categories: Array<keyof Language["about"]["portfolio"]["categories"]>
    workCategories: Array<any>
    stickyStyle: any
    checkStyle: any
};


const makeStyles = (theme: Theme) => {
    return {
        item: {
            borderColor: theme.palette.secondary.main,
            borderStyle: "solid",
            borderWidth: 1,
            textAlign: "center",
            zIndex: -1,
            maxWidth: "300px",

        },
        row: {},
        companyName: {
            textAlign: "left",
            maxWidth: "150px"
        }
    }
}

export default function PortfolioRow(props: PortfolioRowProps) {
    const belgium = require("../../../../assets/belgianFlag.ico")
    const netherlands = require("../../../../assets/dutchFlag.ico")
    const luxemburg = require("../../../../assets/luxemburgFlag.ico")
    const europe = require("../../../../assets/europeanFlag.ico")

    const theme = useTheme();
    const styles = makeStyles(theme);
    const language = useLanguage();

    return <TableRow key={props.name} sx={styles.row}>
        <TableCell sx={[styles.item, props.stickyStyle, styles.companyName]}>{props.name}</TableCell>

        <TableCell sx={[styles.item, props.checkStyle]}>
            {
                props.categories.includes("global") ? 
                <Tooltip title={language.about.portfolio.categories.global}>
                    <Public color="primary" fontSize="medium"/>
                </Tooltip> : props.categories.includes("europe") ?
                <Tooltip title={language.about.portfolio.categories.europe}>
                    <img style={{width: "27px"}} src={europe} alt={language.about.portfolio.categories.europe}/>
                </Tooltip> : props.categories.includes("benelux") ?
                <Tooltip title={language.about.portfolio.categories.benelux}>
                    <Box style={{display: "block", }}>
                        <img style={{width: "27px", marginRight: "-3px"}} src={belgium} alt={language.about.portfolio.categories.benelux}/> 
                        <img style={{width: "20px"}} src={netherlands} alt={language.about.portfolio.categories.benelux}/> 
                        <img style={{width: "20px"}} src={luxemburg} alt={language.about.portfolio.categories.benelux}/> 
                    </Box>
                </Tooltip> : null
            }
            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                {
                    props.categories.includes("belgium") ?
                    <Tooltip title={language.about.portfolio.categories.belgium}>
                        <Box style={{display: "block", }}>
                            <img style={{width: "27px", marginRight: "-3px"}} src={belgium} alt={language.about.portfolio.categories.belgium}/> 
                        </Box>
                    </Tooltip> : null
                }
                {
                    props.categories.includes("luxemburg") ?
                    <Tooltip title={language.about.portfolio.categories.luxemburg}>
                        <Box style={{display: "block", }}>
                            <img style={{width: "20px"}} src={luxemburg} alt={language.about.portfolio.categories.luxemburg}/> 
                        </Box>
                    </Tooltip> : null
                }
            </Box>
        </TableCell>
        {
            props.workCategories.map((v: string) => {
                const category = v as keyof Language["about"]["portfolio"]["categories"];
                return <TableCell key={category as any} sx={[styles.item, props.checkStyle]}>
                    {props.categories.includes(category) ? 
                        <Tooltip title={language.about.portfolio.categories[category]}>
                            <CheckCircle color="success" />
                        </Tooltip> : <></>}
                </TableCell>
            })
        }
    </TableRow>
}